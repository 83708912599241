@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

    body {
        font-family: 'Montserrat', sans-serif;
        color: #3c4852;
        font-size: 16px;
        font-weight: 400;
    }

    /* Define the styles for the scrollbar */
    ::-webkit-scrollbar {
        @apply w-[6px];
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        @apply rounded-lg;
    }

    ::-webkit-scrollbar-thumb {
        @apply rounded-lg;
        background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    /* Loading */
    .solar-system {
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orbit {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1d2630;
        border-radius: 50%;
    }

    .earth-orbit {
        width: 165px;
        height: 165px;
        animation: spin 12s linear 0s infinite;
    }

    .venus-orbit {
        width: 120px;
        height: 120px;
        animation: spin 7.4s linear 0s infinite;
    }

    .mercury-orbit {
        width: 90px;
        height: 90px;
        animation: spin 3s linear 0s infinite;
    }

    .planet {
        position: absolute;
        top: -5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #3ff9dc;
    }

    .sun {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #ffab91;
    }

    @keyframes spin {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(359deg);
        }
    }
}
